import { Image, Text, useSx, View } from "dripsy"
import { useCalculateFontSize } from "app/redux/hooks/use-calculate-font-size";
import { Button } from "app/components/button";
import { Icon } from "app/components/icon/icon";
import { useDim } from "app/redux/hooks/useDim";
import { Dimensions } from "react-native";

const ChooseGennoctua = () => {
    const list = [
        {
            title: 'Upload & Visualize', icon: require('../../assets/assistance.png'), desc: 'Snap a picture of your space, and our AI will show you exactly where your furniture fits, making it easy to create the perfect layout without lifting a finger.'
        },
        {
            'title': 'Smart Assembly Guidance', icon: require('../../assets/assembling.png'), desc: 'Say goodbye to long, complicated manuals. Our AI converts them into step-by-step videos in your preferred language, helping you assemble like a pro.'
        },
        {
            'title': 'Real-Time Assistance', icon: require('../../assets/assistance.png'), desc: 'Stuck? Our AI assistant will look at your project and provide instant voice-over guidance, ensuring everything is assembled just right.'
        }
    ];
    const sx = useSx();
    const getFontSize = useCalculateFontSize();


    return (
        <View style={sx({
            width: '100%',
            minHeight: ['70vh'],
            flexDirection: 'column',
            paddingX: ['5%', '5%', '10%'],
            backgroundColor: '#F8FBFA'
        })}>
            <View style={sx({
                width: '100%',
                height: 46,
            })}>
                <Text
                    style={sx(
                        {
                            fontSize: getFontSize(48, 20),
                            fontWeight: '700',
                            textAlign: 'left',
                            lineHeight: '46px',
                            letterSpacing: '-0.02em',
                            color: '$primary',
                        }
                    )}
                >
                    How We Work
                </Text>
            </View>
            <View style={sx({
                flexDirection: ['column', 'column', 'row'],
                flex: 1,
                height: 'calc(100% - 46px)',
            })}>

                {list.map((item, index) => {
                    return (
                        index == 1 ? <Card index={index} item={item} /> :
                            <SencondCard item={item} index={index} />
                    )
                })}


            </View>
            <View
                style={sx({
                    width: "100%",
                    justifyContent: 'center',
                    alignItems: ['flex-start', 'flex-start', 'center']
                })}
            >
                <Text

                    style={sx(
                        {
                            fontSize: getFontSize(48, 20),
                            fontWeight: '700',
                            textAlign: 'left',
                            lineHeight: '46px',
                            letterSpacing: '-0.02em',
                        }
                    )}
                >
                    Get Started with Home Makeover
                </Text>
                <Button
                    style={sx({
                        height: 40,
                        maxWidth: 300,
                        backgroundColor: '#2D1D1D',
                        padding: 10
                    })}
                    textStyle={{
                        fontSize: 14,
                    }}
                    onPress={() => {
                    }}
                >
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}
                    >
                        <Text style={{
                            color: '#FFF',
                            marginRight: 20
                        }}>
                            Sign Up Now
                        </Text>
                        <Icon name={'arrow'} fill={['#FFF']} style={{
                            transform: [{ rotate: '90deg' }],
                        }} />
                    </View>
                </Button>
            </View>
        </View>

    )
}
export default ChooseGennoctua;


const Card = ({ item, index }) => {

    const sx = useSx();

    return (
        <View key={index} style={sx({
            height: [150, 180, 220,],
            width: ['100%', '100%', '33.33%'],
            marginTop: [0, 0, '5%'],
        })}>
            <View style={sx({
                padding: 20,
                borderWidth: 1,
                borderColor: '#785840',
                margin: 10,
                justifyContent: 'center',
                alignItems: 'center',
                borderTopLeftRadius: 7, borderBottomLeftRadius: 50, borderTopRightRadius: 50,
                borderBottomRightRadius: 7,
                display: ['none', 'none', 'flex']
            })}>
                <Text style={sx({ fontWeight: 'bold', fontSize: 18, textAlign: 'center' })}>
                    {item.title}
                </Text>
                <Text style={sx({ fontSize: 14, marginTop: 10, textAlign: 'center' })}>
                    {item.desc}
                </Text>
            </View>
            <View style={sx({
                display: ['flex', 'flex','none']

            })}>
                <SencondCard index={index} item={item} />
            </View>
        </View>
    )

};


const SencondCard = ({ item, index }) => {
    const sx = useSx();
    const getFontSize = useCalculateFontSize();
    return (
        <View key={`${index}-index-data`}
            style={sx({
                height: [150, 180, 220,],
                width: ['100%', '100%', '33.33%'],
                padding: [0, 0, '5%'],
                flexDirection: ['column'],
            })}>

            <Text style={sx({
                fontSize: getFontSize(48, 20),
                fontWeight: '700',
                textAlign: 'left',
                lineHeight: '46px',
                letterSpacing: '-0.02em',
                color: '#030605',
                fontStyle: 'italic',
                marginBottom: 5
            })}>{item.title}</Text>
            <Text style={sx({
                fontSize: getFontSize(24, 12),
                fontWeight: '700',
                textAlign: 'left',
                lineHeight: '24px',
                letterSpacing: '-0.02em',
                color: '#5C5E5E',
            })}>
                {item.desc}
            </Text>
        </View>
    )
}