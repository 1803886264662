import { Image, Text, useSx, View } from "dripsy"
import Layout from "../layout"
import { useCalculateFontSize } from "app/redux/hooks/use-calculate-font-size";
import { Button } from "app/components/button";
import { Icon } from "app/components/icon/icon";
import { useDim } from "app/redux/hooks/useDim";
import ContactUs from "./contactus";
import HowWeWork from "./how-we-work";
import Aboutus from "./aboutus";
import ExploreOurFeatures from "./explore-our-features";
import ChooseGennoctua from "./choose-gennoctua";
import UserReview from "./user-reviews";
import OurBlog from "./our-blog";
import GennoctuaFooter from "./footer";

const HomePage = () => {
    const sx = useSx();
    const getFontSize = useCalculateFontSize();
    const { height, width } = useDim();

    return (
        <Layout
            subContainerStyle={{ maxWidth: ['100%', '100%'] }}
            isProfileIcon={false}
            scrollEnabled={false}
            containerStyle={sx({
                paddingX: [0],
                paddingY: [0],
            })}

            mainContainerStyle={sx({
                width: '100%',
                height: '100%',
            })}
            loginSignUpBtn
            logoPositionCenter={false}
        >

     <ContactUs />
            <Aboutus />
              <HowWeWork /> 
            <ExploreOurFeatures />
           <ChooseGennoctua />
           <UserReview />
           <OurBlog />
           <GennoctuaFooter />
        </Layout>
    )
}

export default HomePage;


