import { Image, Text, useSx, View } from "dripsy"
import Layout from "../layout"
import { useCalculateFontSize } from "app/redux/hooks/use-calculate-font-size";
import { Button } from "app/components/button";
import { Icon } from "app/components/icon/icon";
import { useDim } from "app/redux/hooks/useDim";

const HowWeWork = () => {
    const list = [
        {
            title: 'Upload & Visualize', icon: require('../../assets/assistance.png'), disc: 'Snap a picture of your space, and our AI will show you exactly where your furniture fits, making it easy to create the perfect layout without lifting a finger.'
        },
        {
            'title': 'Smart Assembly Guidance', icon: require('../../assets/assembling.png'), disc: 'Say goodbye to long, complicated manuals. Our AI converts them into step-by-step videos in your preferred language, helping you assemble like a pro.'
        },
        {
            'title': 'Real-Time Assistance', icon: require('../../assets/assistance.png'), disc: 'Stuck? Our AI assistant will look at your project and provide instant voice-over guidance, ensuring everything is assembled just right.'
        }
    ];
    const sx = useSx();
    const getFontSize = useCalculateFontSize();

    return (
        <View style={sx({
            width: '100%',
            minHeight: ['50vh','50vh','80vh'],
            flexDirection: 'column',
            paddingX: ['5%','5%','10%'],
            backgroundColor: '#F8FBFA'
        })}>
            <View style={sx({
                width: '100%',
                height: 46,
            })}>
                <Text
                    style={sx(
                        {
                            fontSize: getFontSize(48, 20),
                            fontWeight: '700',
                            textAlign: 'left',
                            lineHeight: '46px',
                            letterSpacing: '-0.02em',
                            color: '$primary',
                        }
                    )}
                >
                    How We Work
                </Text>
            </View>
            <View style={sx({
                flexDirection: ['column', 'column', 'row'],
                flex: 1,
                height: 'calc(100% - 46px)',
            })}>

                {list.map((item, index) => {
                    return (
                        <View key={`${index}-index-data`} style={sx({
                            height: [220,300,500],
                            width: ['100%', '100%', '33.33%'],
                            padding: [0,0,'5%'],
                            flexDirection: ['column'],
                            backgroundColor: index % 2 ? '#FFF' : '#F8FBFA'
                        })}>
                            <Image
                                resizeMode={'contain'}
                                style={[sx({ width: ['50px', '146px'], height: ['50px', '146px'] })]}
                                source={item.icon}
                                resizeMethod={'scale'}
                                alt='aboutUs'
                            />
                            <View style={{ height: 10 }} />
                            <Text style={sx({
                                fontSize: getFontSize(48, 20),
                                fontWeight: '700',
                                textAlign: 'left',
                                lineHeight: '46px',
                                letterSpacing: '-0.02em',
                                color: '#030605',
                                fontStyle: 'italic',
                                marginBottom: 5
                            })}>{item.title}</Text>
                            <Text style={sx({
                                fontSize: getFontSize(24, 12),
                                fontWeight: '700',
                                textAlign: 'left',
                                lineHeight: '24px',
                                letterSpacing: '-0.02em',
                                color: '#5C5E5E',
                            })}>
                                {item.disc}
                            </Text>
                        </View>
                    )
                })}


            </View>
            <View
                style={sx({
                    width: "100%",
                    justifyContent: 'center',
                    alignItems:['flex-start','flex-start' ,'center']
                })}
            >
                <Text

                    style={sx(
                        {
                            fontSize: getFontSize(48, 20),
                            fontWeight: '700',
                            textAlign: 'left',
                            lineHeight: '46px',
                            letterSpacing: '-0.02em',
                        }
                    )}
                >
                    Try Our AI Tools for Free
                </Text>
                <Button
                    style={sx({
                        height: 40,
                        maxWidth: 300,
                        backgroundColor: '#2D1D1D',
                        padding: 10
                    })}
                    textStyle={{
                        fontSize: 14,
                    }}
                    onPress={() => {
                    }}
                >
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}
                    >
                        <Text style={{
                            color: '#FFF',
                            marginRight: 20
                        }}>
                            Upload Your First Image
                        </Text>
                        <Icon name={'arrow'} fill={['#FFF']} style={{
                            transform: [{ rotate: '90deg' }],
                        }} />
                    </View>
                </Button>
            </View>
        </View>

    )
}
export default HowWeWork;